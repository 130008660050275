<template>
  <div class="index" id="index">
    <div class="top">
      <headers :headSelectIndex='1'></headers>
      <div class="container">
        <div class="containerBox">
          <div class="swiper-container swiper-container1">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="../assets/gy.jpg" alt="">
              </div>
              <div class="swiper-slide">
                <img src="../assets/banner_a.jpg" alt="">
              </div>
              <div class="swiper-slide" @click="goMember">
                <img src="../assets/banner_b.jpg" alt="">
              </div>
              <div class="swiper-slide" @click="goTuBanner">
                <img src="../assets/banner_C1.png" alt="">
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="recommend">
      <div class="r_left">
        <div class="r_top">
          <img src="../assets/icon_tuijian15.png" alt="">
          <div>一对一，在线咨询</div>
        </div>
        <div class="pic_box">
          <div class="pic" @mouseover="showPic=true" @click="goAsk(1)">
            <div class="word">图文咨询</div>
            <div class="des">支持<span>文字、图片</span>一对一在线咨询</div>
            <div class="money">¥<span>38</span></div>
            <div class="mark" v-show="showPic" @mouseover="showPic=true" @mouseout="showPic=false">
              <div>立即咨询</div>
            </div>
          </div>
          <div class="pic r_vip" @mouseover="showTel=true" @click="goAsk(2)">
            <div class="word">电话咨询</div>
            <div class="des">支持文字、图片 一对一<span>电话沟通</span></div>
            <div class="money">¥<span>68</span></div>
            <div class="mark" v-show="showTel" @mouseover="showTel=true" @mouseout="showTel=false">
              <div>立即咨询</div>
            </div>
          </div>
        </div>
      </div>
      <div class="r_right">
        <div class="r_title">用户动态</div>
        <div style="margin:20px;padding-bottom:20px;overflow: hidden;height:220px" class="swiper-no-swiping">
          <div class="swiper-container swiper-container2">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in trendList" :key="index">
                <div class="user_info">
                  <div class="user">用户 {{item.Moblie}}</div>
                  <div class="time">{{item.Date}}</div>
                </div>
                <div class="case">
                  <div>在{{item.OrderFrom}}上购买了<span style="color:#21D0C8">{{item.OrderTitle}}</span>服务</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lawyer">
      <div class="r_top">
        <img src="../assets/icon_lawyer16.png" alt="">
        <div style="width:116px">全网精选，服务专业</div>
      </div>
      <div class="more_lawyer" @mouseover="moreSrc=require('../assets/icon_return_focused12.png')"
        @mouseout="moreSrc=require('../assets/icon_return_normal12.png')" @click="moreLawyer">
        <span>更多律师</span>
        <img :src="moreSrc" alt="">
      </div>
      <div style="margin:14px 0;">
        <div class="swiper-container swiper-container3" style="height:275px" ref="mySwiper">
          <div class="swiper-wrapper" style="display: flex;align-items: center;">
            <div class="swiper-slide swiper-slide1" v-for="(item,index) in lawyerList" :key="index+'1'">
              <div class="active_card" @mouseover="showLawyer=true">
                <div class="horn">
                  <div class="h_pic"><img :src="item.icon" alt=""></div>
                  <span>{{item.LawyerLevel}}</span>
                </div>
                <div class="info">
                  <img :src="item.LawyerPic" alt="">
                  <div class="msg">
                    <div style="padding-top:3px">
                      <span class="lawyer_name">{{item.LawyerName.substring(0,1)}}律师</span>
                      <span class="year">执业{{item.LawyerPracticeYears}}年</span>
                    </div>
                    <div class="goodat">
                      <span>擅长：</span>
                      <span style="color:#333">{{item.ProfessionalField}}</span>
                    </div>
                    <div>
                      <span>近30天：</span>
                      <span style="color:#333">服务 <span style="color:#FE9A2F">{{item.ServiceCountMonth}}</span> 人 好评率
                        <span style="color:#FE9A2F">{{item.Satisfaction}}</span></span>
                    </div>
                  </div>
                </div>
                <div class="price_list">
                  <div class="lis">
                    <p>私人律师</p>
                    <div class="person">¥{{item.ProductPrice_sr}}/月</div>
                    <img class="recommends" src="../assets/icon_recommend14.png" alt="">
                  </div>
                  <div class="lis">
                    <p>图文咨询</p>
                    <div class="person">￥{{item.ProductPrice_wz}}/次</div>
                  </div>
                  <div class="lis">
                    <p>电话咨询</p>
                    <div class="person">￥{{item.ProductPrice_dh}}/次</div>
                  </div>
                  <div class="points">···</div>
                </div>
                <div class="assess">
                  <p>最新评价</p>
                  <div class="a_top" v-if="item.LatestComment">
                    <div>
                      <span class="mobile">{{item.LatestComment.MemberMoblie_kh}}</span>
                      <label v-for="index of item.LatestComment.Stars" :key="index+'2'">
                        <img class="satrs" src="../assets/icon_shape_selected.png" alt="">
                      </label>
                      <label v-for="index of (5-item.LatestComment.Stars)" :key="index+'3'">
                        <img class="satrs" src="../assets/icon_shape_normal.png" alt="">
                      </label>
                    </div>
                    <div>{{item.LatestComment.CreateDate }}</div>
                  </div>
                  <div class="a_content" v-if="item.LatestComment">{{item.LatestComment.CommentDes}}</div>
                  <div class="a_content" style="text-align:center;margin-top:20px" v-if="!item.LatestComment">暂无评价</div>
                </div>
              </div>
            </div>
            <!-- <div class="swiper-slide swiper-slide1" v-for="(item,index) in lawyerList" :key="index+'1'"
              @click="goLawyerDetail(item.LawyerID)">
              <div class="active_card" v-if="active==index" @mouseover="showLawyer=true">
                <div class="horn">
                  <div class="h_pic"><img src="../assets/icon_xinrui24.png" alt=""></div>
                  <span>{{item.LawyerLevel}}</span>
                </div>
                <div class="info">
                  <img :src="item.LawyerPic" alt="">
                  <div class="msg">
                    <div style="padding-top:3px">
                      <span class="lawyer_name">{{item.LawyerName.substring(0,1)}}律师</span>
                      <span class="year">执业{{item.LawyerPracticeYears}}年</span>
                    </div>
                    <div class="goodat">
                      <span>擅长领域：</span>
                      <span style="color:#333">{{item.ProfessionalField}}</span>
                    </div>
                    <div>
                      <span>近30天：</span>
                      <span style="color:#333">服务 <span style="color:#FE9A2F">{{item.ServiceCountMonth}}</span> 人 好评率
                        <span style="color:#FE9A2F">{{item.Satisfaction}}</span></span>
                    </div>
                  </div>
                </div>
                <div class="price_list">
                  <div class="lis">
                    <p>私人律师</p>
                    <div class="person">¥{{item.ProductPrice_sr}}/月</div>
                    <img class="recommends" src="../assets/icon_recommend14.png" alt="">
                  </div>
                  <div class="lis">
                    <p>图文咨询</p>
                    <div class="person">￥{{item.ProductPrice_wz}}/次</div>
                  </div>
                  <div class="lis">
                    <p>电话咨询</p>
                    <div class="person">￥{{item.ProductPrice_dh}}/次</div>
                  </div>
                  <div class="points">···</div>
                </div>
                <div class="assess">
                  <p>最新评价</p>
                  <div class="a_top" v-if="item.LatestComment">
                    <div>
                      <span class="mobile">{{item.LatestComment.MemberMoblie_kh}}</span>
                      <label v-for="index of item.LatestComment.Stars" :key="index+'2'">
                        <img class="satrs" src="../assets/icon_shape_selected.png" alt="">
                      </label>
                      <label v-for="index of (5-item.LatestComment.Stars)" :key="index+'3'">
                        <img class="satrs" src="../assets/icon_shape_normal.png" alt="">
                      </label>
                    </div>
                    <div>{{item.LatestComment.CreateDate }}</div>
                  </div>
                  <div class="a_content" v-if="item.LatestComment">{{item.LatestComment.CommentDes}}</div>
                  <div class="a_content" style="text-align:center;margin-top:20px" v-if="!item.LatestComment">暂无评价</div>
                </div>
              </div>
              <div class="card" v-else>
                <img class="l_avator" :src="item.LawyerPic" alt="">
                <p class="name">{{item.LawyerName.substring(0,1)}}律师</p>
                <div class="experience">
                  <div>{{item.LawyerLevel}}律师</div>
                  <div class="shu"></div>
                  <div>执业{{item.LawyerPracticeYears}}年</div>
                </div>
              </div>
              <div class="mark" v-show="showLawyer &&active==index" @mouseover="showLawyer=true"
                @mouseout="showLawyer=false">
                <div style="width:100px;" @click="ask(item.LawyerID)">咨询</div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mu">
      <div class="r_top">
        <img src="../assets/icon_contract16.png" alt="">
        <div style="width:116px">精选范本，一键下载</div>
      </div>
      <div class="contract">
        <div class="c_list" v-for="(item,index) in temList" :key="index+'4'" @click="goMu(item.DocGuid)">
          <div class="kua">
            <img class="vip" src="../assets/icon_free20.png" alt="">
            <img class="c_pics" :src="item.DocPreviewPic?item.DocPreviewPic:require('../assets/1.png')" alt="">
          </div>
          <div class="c_name">{{item.DocName}}</div>
          <div class="m_mon">
            <span>¥</span>
            <span style="font-size:20px;margin-left:2px">9.9</span>
          </div>
        </div>
      </div>
      <div class="all" @click="checkAll">查看全部</div>
    </div>
    <div class="ques">
      <img class="question" src="../assets/img_entrust280.png" alt="">
      <div class="types">
        <div class="select_p">请选择问题类型</div>
        <div class="t_con">
          <div class="q_box" :class="{'add_type':selected==1}" @click="selected=1">
            <img style="width:34px;height:31px" src="../assets/icon_hunyin31.png" alt="">
            <div class="qu_name">婚姻家庭</div>
            <img v-if="selected==1" class="selected" src="../assets/icon_tick12.png" alt="">
          </div>
          <div class="q_box" :class="{'add_type':selected==2}" @click="selected=2">
            <img style="width:29px;height:31px" src="../assets/icon_jiaotgng31.png" alt="">
            <div class="qu_name">交通事故</div>
            <img v-if="selected==2" class="selected" src="../assets/icon_tick12.png" alt="">
          </div>
          <div class="q_box" :class="{'add_type':selected==3}" @click="selected=3">
            <img style="width:36px;height:31px" src="../assets/icon_tudi31.png" alt="">
            <div class="qu_name">土地房产</div>
            <img v-if="selected==3" class="selected" src="../assets/icon_tick12.png" alt="">
          </div>
          <div class="q_box" :class="{'add_type':selected==4}" @click="selected=4">
            <img style="width:27px;height:31px" src="../assets/icon_xingshi31.png" alt="">
            <div class="qu_name">刑事辩护</div>
            <img v-if="selected==4" class="selected" src="../assets/icon_tick12.png" alt="">
          </div>
          <div class="q_box" :class="{'add_type':selected==5}" @click="selected=5">
            <img style="width:24px;height:31px" src="../assets/icon_hetong.png" alt="">
            <div class="qu_name">合同纠纷</div>
            <img v-if="selected==5" class="selected" src="../assets/icon_tick12.png" alt="">
          </div>
        </div>
        <div class="t_con" style="margin-top:10px">
          <div class="q_box" :class="{'add_type':selected==6}" @click="selected=6">
            <img style="width:26px;height:31px" src="../assets/icon_laodong31.png" alt="">
            <div class="qu_name">劳动维权</div>
            <img v-if="selected==6" class="selected" src="../assets/icon_tick12.png" alt="">
          </div>
          <div class="q_box" :class="{'add_type':selected==7}" @click="selected=7">
            <img style="width:25px;height:29px" src="../assets/icon_sunhai31.png" alt="">
            <div class="qu_name">人身损害</div>
            <img v-if="selected==7" class="selected" src="../assets/icon_tick12.png" alt="">
          </div>
          <div class="q_box" :class="{'add_type':selected==8}" @click="selected=8">
            <img style="width:27px;height:31px" src="../assets/icon_jiedai31.png" alt="">
            <div class="qu_name">借贷纠纷</div>
            <img v-if="selected==8" class="selected" src="../assets/icon_tick12.png" alt="">
          </div>
          <div class="q_box" :class="{'add_type':selected==9}" @click="selected=9">
            <img style="width:27px;height:31px" src="../assets/icon_gognsi31.png" alt="">
            <div class="qu_name">公司企业</div>
            <img v-if="selected==9" class="selected" src="../assets/icon_tick12.png" alt="">
          </div>
          <div class="q_box" :class="{'add_type':selected==10}" @click="selected=10">
            <img style="width:30px;height:31px" src="../assets/icon_other.png" alt="">
            <div class="qu_name">其他法律</div>
            <img v-if="selected==10" class="selected" src="../assets/icon_tick12.png" alt="">
          </div>
        </div>
        <div class="btn" @click="outPut">发布案情</div>
      </div>
    </div>
    <div class="serve">
      <div class="ser_box">
        <div style="margin-right:10px">
          <div class="more_serve">律兜更多服务</div>
          <div class="show_p">多元服务，价格透明</div>
        </div>
      </div>
      <div class="six">
        <div class="flex_box">
          <div class="box_six" :class="{'up':hoverIndex==index}" v-for="(item,index) in arr" :key="index+'6'"
            @mouseover="mouseover(index)" @mouseout="hoverIndex=null">
            <img :src="item.src" alt="">
            <p>{{item.name}}</p>
            <div class="lines" v-if="hoverIndex==index"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="down" @click="downApp">下载客户端</div>
    <sideBar></sideBar>
    <FootBox></FootBox>
    <div class="m_adv" v-if="showAdv">
      <div class="adv">
        <img @click="closeAdv" class="close" src="../assets/popup_icon_close.png" alt="">
        <img class="advertise" @click="goTu" src="../assets/123.png" alt="">
      </div>
    </div>
    <div class="bottom_adv" v-if="showBuy">
      <div class="abox">
        <img @click="showBuy=false" class="close_adv" src="../assets/popup_icon_close.png" alt="">
        <img class="adv_buy" @click="goTu" src="../assets/456.png" alt="">
      </div>
    </div>
    <div class="marks" v-if="showSsj">
      <div class="s_box">
        <img @click="showSsj=false" class="s_close" src="../assets/popup_icon_close.png" alt="">
        <div class="s_content">
          <div style="font-size:20px;color:#333333">微信扫码体验服务</div>
          <div style="font-size:14px;color:#666666;margin-top:6px">律兜法律咨询旗下案件服务小程序</div>
        </div>
        <div class="s_code">
          <img src="../assets/ssj.jpg" alt="">
        </div>
      </div>
    </div>
    <img src="../assets/login_bg.png" alt="" style="display:none;">
  </div>
</template>

<script>
  import FootBox from '@/components/FootBox'
  import sideBar from '@/components/sideBar'
  import headers from '@/components/header'
  import Swiper from "swiper"
  import {
    GetUserDynamics,
    GetDayLawyerRecommend,
    GetHomeContractTemplates,
    lawyerproducts,
  } from '@/api/index'
  export default {
    name: 'index',
    data() {
      return {
        active: 1,
        selected: null,
        arr: [{
            src: require('../assets/icon_video82.png'),
            name: '视频咨询'
          },
          {
            src: require('../assets/icon_meet82.png'),
            name: '见面咨询'
          },
          {
            src: require('../assets/icon_lawyer82.png'),
            name: '私人律师'
          },
          {
            src: require('../assets/icon_contract82.png'),
            name: '审改合同'
          },
          {
            src: require('../assets/icon_writ82.png'),
            name: '写文书'
          },
          {
            src: require('../assets/icon_letter82.png'),
            name: '律师函'
          }
        ],
        hoverIndex: null,
        moreSrc: require('../assets/icon_return_normal12.png'),
        showPic: false,
        showTel: false,
        showTime: false,
        showLawyer: false,
        showAdv: true,
        showBuy: false,
        trendList: [],
        lawyerList: [],
        temList: [],
        user: null,
        showSsj: false,
        isPingan:null
      }
    },
    created() {
      this.user = localStorage.getItem('user') && localStorage.getItem('user') != undefined ? JSON.parse(localStorage
        .getItem('user')) : null
      let date = localStorage.getItem('advertise') ? localStorage.getItem('advertise') : '';
      let nowDate = this.getDate();
      if (date && date == nowDate) {
        this.showAdv = false
      } else {
        _hmt.push(['_trackEvent', '新用户首页特惠弹框', 'show', 'pc']);
        this.showAdv = true
        localStorage.setItem('advertise', nowDate)
      }
      this.getTrend()
      this.getTodayLawyer()

      this.getTmp()
    },
    mounted() {
      _hmt.push(['_trackPageview', '/index'])
      this.bannerPoint()
      this.dynamic()
      this.lawyerBanner()
      if(localStorage.getItem('orderfrom')=='pc-zhpf'){
      this.$confirm('即将由律兜法律服务平台为您提供专业的在线法律咨询服务，部分服务为付费服务，请知悉。', '提示', {
          confirmButtonText: '我知道了',
          showCancelButton:false
        });
        }
      this.checkPingan().then(res=>{
        if(res==0){
          this.isPingan=true
        }else{
          this.isPingan=false
        }
      })
    },
    methods: {
      goMember() {
        _hmt.push(['_trackEvent', 'click', '律兜会员banner图', 'pc']);

        if (localStorage.getItem('user')) {
          this.$router.push({
            path: '/member'
          })
        } else {
          this.$router.push({
            path: '/login'
          })
        }
      },
      goLawyerDetail(id) {
        this.$router.push({
          path: '/lawyer',
          query: {
            lawyerguid: id
          }
        })
      },
      bannerPoint() {
        new Swiper(".swiper-container1", {
          // loop: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          updateOnImagesReady: true,
          spaceBetween: 20,
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        })
      },
      dynamic() {
        new Swiper(".swiper-container2", {
          // loop: true,
          slidesPerView: 'auto',
          loopedSlides: 5,
          autoplay: {
            delay: 5000,
          },
          direction: 'vertical',
          spaceBetween: 5,
          slidesPerView: 2.7,
          observer: true,
          observeParents: true,
          observeSlideChildren: true,

        })
      },
      lawyerBanner() {
        let that = this;
        new Swiper(".swiper-container3", {
          autoplay: {
            delay: 5000,
          },
          initialSlide: 1,
          spaceBetween: -60,
          slidesPerView: 3,
          centeredSlides: true,
          observer: true,
          observerParents: true,
          loop: true,
          on: {
            slideChange(e) {
              that.active = this.realIndex
            },
            click(e) {
              that.goLawyerDetail(that.lawyerList[that.active].LawyerID)
            }
          }
        })
      },
      mouseover(e) {
        this.hoverIndex = e
      },
      closeAdv() {
        this.showAdv = false
        this.showBuy = true
      },
      getDate() {
        let myDate = new Date()
        let yy = String(myDate.getFullYear())
        let mm = myDate.getMonth() + 1
        let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
        return yy + '.' + mm + '.' + dd
      },
      getTrend() {
        GetUserDynamics().then(res => {
          if (res.state == 0) {
            this.trendList = res.data
          }
        })
      },
      getTodayLawyer() {
        GetDayLawyerRecommend().then(res => {
          if (res.state == 0) {
            let list = res.data;
            for (let i = 0; i < list.length; i++) {
              if (list[i].ProfessionalField) {
                list[i].ProfessionalField = list[i].ProfessionalField.replace(/(\s*$)/g, "").replace(/ /g, "、");
              }
              if (list[i].LawyerLevel == '新锐') {
                list[i].icon = require('../assets/icon_grade1.png')
              }
              if (list[i].LawyerLevel == '才俊') {
                list[i].icon = require('../assets/icon_grade2.png')
              }
              if (list[i].LawyerLevel == '中坚') {
                list[i].icon = require('../assets/icon_grade3.png')
              }
              if (list[i].LawyerLevel == '明星') {
                list[i].icon = require('../assets/icon_grade4.png')
              }
            }
            for (let i = 0; i < list.length; i++) {
              for (let k = 0; k < list[i].productInfoLst.length; k++) {
                if (list[i].productInfoLst[k].ProductTitle.indexOf('私人') > -1) {
                  list[i].ProductPrice_sr = list[i].productInfoLst[k].ProductPrice
                }
                if (list[i].productInfoLst[k].ProductTitle.indexOf('文字') > -1) {
                  list[i].ProductPrice_wz = list[i].productInfoLst[k].ProductPrice
                }
                if (list[i].productInfoLst[k].ProductTitle.indexOf('电话') > -1) {
                  list[i].ProductPrice_dh = list[i].productInfoLst[k].ProductPrice
                }
              }
            }

            console.log(list)
            this.lawyerList = list
            this.$nextTick(() => {
              this.lawyerBanner()
            })
          }
        })
      },
      getTmp() {
        GetHomeContractTemplates().then(res => {
          if (res.state == 0) {
            this.temList = res.data
          }
        })
      },
      goAsk(e) {
        _hmt.push(['_trackEvent', 'click', '律师推荐-今日推荐律师', 'pc']);
        let title = '';
        if (e == 1) {
          title = '图文咨询'
        } else {
          title = '电话咨询'
        }
        _hmt.push(['_trackEvent', 'click', title, 'pc']);
        if (localStorage.getItem('user')) {
          this.$router.push({
            path: '/ask',
            query: {
              id: e
            }
          })
        } else {
          this.$router.push({
            path: '/login'
          })
        }

      },
      moreLawyer() {
        _hmt.push(['_trackEvent', 'click', '律师推荐-更多律师按钮', 'pc']);
        this.$router.push('/recommend')
      },
      goMu(e) {
        _hmt.push(['_trackEvent', 'click', '合同模板-推荐合同模板', 'pc']);
        this.$router.push({
          path: '/contractDetail',
          query: {
            guid: e
          }
        })
      },
      checkAll() {
        _hmt.push(['_trackEvent', 'click', '合同模板-查看全部按钮', 'pc']);
        this.$router.push('/contract')
      },
      ask(e) {
        this.$router.push({
          path: '/lawyer',
          query: {
            lawyerguid: e
          }
        })
      },
      outPut() {
        this.showSsj = true
        _hmt.push(['_trackEvent', 'click', '案件委托-发布案情按钮', 'pc']);
      },
      goTu() {
        _hmt.push(['_trackEvent', 'click', '新用户首单特惠-立即领取按钮', 'pc']);
        if (localStorage.getItem('user')) {
          this.$router.push({
            path: '/ask',
            query: {
              id: 1
            }
          })
        } else {
          this.$router.push({
            path: '/login'
          })
        }
      },
      goTuBanner() {
        _hmt.push(['_trackEvent', 'click', '新用户首单特惠banner图', 'pc']);
        if (localStorage.getItem('user')) {
          this.$router.push({
            path: '/ask',
            query: {
              id: 1
            }
          })
        } else {
          this.$router.push({
            path: '/login'
          })
        }
      },
      downApp() {
        _hmt.push(['_trackEvent', 'click', '更多服务-下载客户端按钮', 'pc']);
        this.$router.push('download')
      }

    },
    components: {
      FootBox,
      sideBar,
      headers
    }
  }

</script>

<style scoped lang="scss">
  .marks {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.7);
    z-index: 99999;

    .s_box {
      position: relative;
      width: 360px;
      height: 750px;
      background-image: url('../assets/pop_img_case.png');
      background-repeat: no-repeat;
      background-size: 100%;

      .s_content {
        position: absolute;
        bottom: 310px;
        left: 0;
        width: 317px;
        text-align: center;
      }

      .s_close {
        position: absolute;
        right: 10px;
        top: 320px;
        width: 30px;
        height: 30px;
      }

      .s_code {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 110px;
        left: 70px;
        width: 180px;
        height: 180px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.31);
        border-radius: 20px;

        img {
          width: 156px;
          height: 156px;
        }
      }
    }
  }

  .index {
    background: #F3F3F3;
  }

  .top {
    background: linear-gradient(180deg, #21D0C8 0%, #20C0F5 60%, #F3F3F3 85%);
  }

  .containerBox {
    width: 940px;
    height: 320px;
    margin: auto;

    .swiper-container {
      border-radius: 20px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
  }

  .swiper-container-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    width: auto;
    left: 47%;
    bottom: 14px;
    line-height: 18px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
  }

  .recommend {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    width: 940px;
    height: 320px;
  }

  .r_left {
    width: 620px;
    height: 320px;
    background: #FFFFFF;
    border-radius: 20px;
  }

  .r_top {
    display: flex;
    align-items: center;
    padding: 20px 26px 0;

    img {
      margin-right: 7px;
      width: 68px;
      height: 15px;
    }

    div {
      width: 103px;
      height: 16px;
      text-align: center;
      background: #21D0C8;
      border-radius: 2px;
      font-size: 12px;
      color: #FFFFFF;
    }
  }

  .pic_box {
    margin: 15px 20px;
    display: flex;
    justify-content: space-between;

    .pic {
      position: relative;
      width: 280px;
      height: 250px;
      background-image: url('../assets/btn_bg_photo250.png');
      background-size: 100%;
      background-repeat: no-repeat;
      overflow: hidden;
      border-radius: 14px;


    }

    .r_vip {
      background-image: url('../assets/btn_bg_phone250.png')
    }
  }

  .mark {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    border-bottom: 6px solid #21D0C8;
    z-index: 9;

    div {
      width: 100px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      background: #21D0C8;
      border-radius: 15px;
      font-size: 16px;
      color: #fff;
      cursor: default;
    }
  }

  .word {
    padding: 40px 28px 0;
    font-size: 26px;
    color: #333333;
    font-weight: bold;
  }

  .des {
    margin: 20px 0 20px 28px;
    width: 121px;
    color: #333333;
    font-size: 16px;

    span {
      color: #21D0C8;
    }
  }

  .money {
    margin: 69px 0 0 28px;
    color: #F24537;
    font-size: 16px;

    span {
      margin-left: 5px;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .r_right {
    width: 300px;
    height: 320px;
    background: #FFFFFF;
    border-radius: 20px;

  }

  .r_title {
    padding: 20px 20px 0;
    color: #000000;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
  }

  .user_info {
    display: flex;
    justify-content: space-between;
    font-size: 12px;

    .user {
      color: #000000;
    }

    .time {
      color: #AAAAAA;
    }
  }

  .case {
    padding: 14px 20px 0 16px;
    width: 260px;
    height: 59px;
    font-size: 14px;
    color: #666666;
    background-image: url('../assets/img_box59.png');
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: border-box;

    div {
      width: 223px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  .lawyer {
    position: relative;
    width: 940px;
    margin: auto;
    height: 350px;
    background: #FFFFFF;
    border-radius: 20px;

    .more_lawyer {
      position: absolute;
      top: 25px;
      right: 18px;
      display: flex;
      align-items: center;
      color: #AAAAAA;
      font-size: 13px;
      cursor: default;

      img {
        margin-left: 8px;
        width: 8px;
        height: 12px;
      }
    }

    .more_lawyer:hover {
      color: #000000;
    }
  }

  .swiper-container2 {
    height: 240px;

    .swiper-slide {
      cursor: default;
    }
  }

  .swiper-slide1 {
    position: relative;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.0800);
    border-radius: 14px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .center {
    width: 360px;
    height: 265px;
  }

  .card {
    padding-top: 18px;
    text-align: center;
    box-sizing: border-box;

    .l_avator {
      width: 96px;
      height: 96px;
      border-radius: 50%;
    }

    .name {
      margin: 5px 0 5px;
      font-weight: bold;
      font-size: 32px;
      color: #333333;
    }

    .experience {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666666;
      font-size: 22px;

      .shu {
        margin: 0 8px;
        width: 1px;
        height: 19px;
        background: #666666;
      }
    }
  }

  .active_card {
    position: relative;
    font-size: 14px;
    color: #666666;
    width: 100%;
    height: 100%;

    .horn {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      width: 84px;
      height: 28px;
      border-radius: 14px 14px 0px 14px;
      background: #F5F5F8;

      .h_pic {
        padding-top: 2px;
        width: 42px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 0 14px 14px 14px;
        background: #000000;
        box-sizing: border-box;

        img {
          width: 29px;
          height: 24px;
        }
      }

      span {
        margin-left: 7px;
      }
    }
  }

  .info {
    display: flex;
    padding: 20px;
    box-sizing: border-box;

    img {
      margin-right: 8px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }

  .msg {
    font-size: 13px;
    color: #666666;
  }

  .lawyer_name {
    color: #333333;
    font-size: 20px;
    font-weight: bold;
  }

  .year {
    margin-left: 8px;
    font-size: 14px;
    color: #666666;
  }

  .goodat {
    margin: 6px 0 4px;
  }

  .price_list {
    display: flex;
    margin: 0 20px 20px;
    justify-content: space-between;
    cursor: default;

    .lis {
      position: relative;
      width: 90px;
      height: 50px;
      padding: 7px 0 8px 12px;
      background: #F5F5F5;
      border-radius: 6px;
      box-sizing: border-box;

      p {
        font-weight: 500;
        color: #333333;
        font-size: 14px;
      }

      .person {
        font-size: 14px;
        font-weight: 500;
        color: #F24537;
      }

      .recommends {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 28px;
        height: 14px;
      }
    }

    .points {
      width: 26px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #F5F5F5;
      border-radius: 6px;
      color: #333;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .assess {
    margin: 20px;

    p {
      margin-bottom: 5px;
      font-size: 14px;
      color: #666666;
    }

    .a_top {
      display: flex;
      justify-content: space-between;
    }

    .a_content {
      margin-top: 5px;
      font-size: 14px;
      color: #666666;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .satrs {
    margin-right: 3px;
    width: 13px;
    height: 14px;
  }

  .mobile {
    margin-right: 5px;
    font-size: 14px;
    color: #000000;
  }

  .swiper-container3 {
    width: 100%;
  }

  // .swiper-container3 .swiper-slide-active,
  // .swiper-container3 .swiper-slide-duplicate-active {
  //   width: 360px !important;
  //   height: 265px !important;
  // }


  .mu {
    margin: 20px auto;
    padding-bottom: 27px;
    width: 940px;
    background: #FFFFFF;
    border-radius: 20px;
  }

  .contract {
    margin: 20px 40px 0;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;

    .c_list {
      margin-right: 21px;
      margin-bottom: 30px;

      .kua {
        position: relative;
        width: 148px;
        height: 210px;
        background: #FFFFFF;
        border: 1px solid #D2D2D2;
        border-radius: 10px;
        overflow: hidden;

        .vip {
          position: absolute;
          left: 0;
          top: 0;
          width: 70px;
          height: 20px;
          z-index: 99;
        }

        .c_pics {
          width: 100%;
          height: 100%;
          transition: all 0.5s;
        }


      }

      .c_name {
        margin: 6px 0 6px;
        width: 130px;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .c_list:hover {
    .kua {
      border: 1px solid #21D0C8;

      .c_pics {
        transform: scale(1.2);
      }
    }

    .c_name {
      color: #21D0C8;
    }


  }

  .m_mon {
    font-size: 14px;
    color: #F24537;
  }

  .all {
    margin: 0 auto 0;
    width: 120px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #F3F3F3;
    border-radius: 12px;
    font-size: 14px;
    color: #666;
    cursor: default;
  }

  .all:hover {
    background: #21D0C8;
    color: #FFFFFF;
  }

  .ques {
    display: flex;
    padding: 20px;
    margin: 20px auto;
    width: 940px;
    height: 320px;
    background: #FFFFFF;
    border-radius: 20px;
    box-sizing: border-box;
  }

  .question {
    margin-right: 20px;
    width: 380px;
    height: 280px;
    border-radius: 20px;
  }

  .types {
    width: 500px;
    height: 280px;
    background: #F7F7F7;
    border-radius: 14px;
  }

  .select_p {
    padding: 19px 30px 17px;
    color: #000;
    font-size: 18px;
  }

  .t_con {
    display: flex;
    margin: 0 40px;
    justify-content: space-between;

    .q_box {
      position: relative;
      padding-top: 10px;
      width: 76px;
      height: 76px;
      text-align: center;
      border-radius: 8px;
      box-sizing: border-box;

      .qu_name {
        margin-top: 3px;
        color: #333;
        font-size: 14px;
        cursor: default;
      }

      .selected {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 12px;
        height: 12px;
      }
    }
  }

  .q_box:hover {
    background: #fff;
  }

  .btn {
    margin: 5px auto;
    width: 174px;
    height: 46px;
    line-height: 40px;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    background-image: url('../assets/btn_fabu46.png');
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: default;
  }

  .add_type {
    background: #fff;
  }

  .serve {
    margin-bottom: 140px;
    position: relative;
    height: 300px;
    background: #0B9AFB;

    .ser_box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: auto;
      width: 553px;
      height: 100%;
      background-image: url('../assets/img_more300.png');
      background-repeat: no-repeat;
      background-size: 100%;

      .more_serve {
        font-size: 40px;
        color: #FFFFFF;
        font-weight: bold;
      }

      .show_p {
        margin-top: 10px;
        font-size: 18px;
        color: #fff;
      }
    }
  }

  .six {
    position: absolute;
    left: 0;
    bottom: -100px;
    width: 100%;

    .flex_box {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: auto;
      width: 940px;
      height: 170px;
      text-align: center;
    }
  }

  .box_six {
    position: relative;
    padding-top: 25px;
    width: 140px;
    height: 150px;
    background: #FFFFFF;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.0800);
    border-radius: 10px;
    text-align: center;
    box-sizing: border-box;
    transition: margin 0.5s;
    -webkit-transition: margin 0.5s;
    overflow: hidden;

    img {
      width: 80px;
      height: 82px;
    }

    p {
      font-weight: 16px;
      color: #333333;
    }
  }

  .up {
    margin-bottom: 20px;
    box-shadow: 0px 7px 25px 0px rgba(11, 154, 251, 0.4000);
  }

  .lines {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 140px;
    height: 4px;
    background: #0B9AFB;
    border-radius: 10px;
  }

  .down {
    margin: 0 auto 60px;
    width: 160px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    border: 1px solid #0B9AFB;
    border-radius: 17px;
    color: #0B9AFB;
    font-size: 18px;
    cursor: default;
  }

  .m_adv {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.7);
    z-index: 9999;

    .adv {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 940px;
      height: 320px;

      .close {
        width: 30px;
        height: 30px;
      }

      .advertise {
        width: 940px;
        height: 290px;
      }
    }
  }

  .bottom_adv {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 290px;
    z-index: 999;

    .abox {
      position: relative;
      margin: auto;
      width: 940px;
      height: 100%;

      .close_adv {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
      }

      .adv_buy {
        width: 100%;
        height: 290px;
      }
    }
  }

</style>
<style>
  .swiper-pagination-bullet {
    background: #fff !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet-active {
    background: #21D0C8 !important;
  }

  .swiper-container3 .swiper-slide {
    font-size: 18px;
    background: #fff;
    width: 360px;
    height: 270px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    /* -webkit-justify-content: center; */
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.6);
    opacity: 0.7;
  }

  .swiper-container3 .swiper-slide-active {
    width: 360px !important;
    opacity: 1;
  }

  .swiper-container3 .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1);
  }

</style>
